import { z } from "zod";
import {
  PaginationMetaResponseSchema,
  PaginationQuerySchema,
} from "./Pagination";

export enum CategoryBucket {
  Marketing = "Marketing",
  Emails = "Emails",
  Articles = "Articles",
  Business = "Business",
  Employee = "Employee",
  Partnership = "Partnership",
  Operations = "Operations",
  Product = "Product",
  Newsletter = "Newsletter",
  Linkedin = "LinkedIn",
  Twitter = "Twitter",
  Facebook = "Facebook",
  Customer = "Customer",
  PressRelease = "Press Release",
  CreativeBrief = "Creative Brief",
}

export const categoryBuckets = Object.values(CategoryBucket);

export const ListCategoriesQuerySchema = PaginationQuerySchema;

export const CategorySchema = z.object({
  id: z.string(),
  category: z.nativeEnum(CategoryBucket),
});

export const ListCategoriesResponseSchema = z
  .object({
    items: z.array(CategorySchema),
  })
  .merge(PaginationMetaResponseSchema);

export type Category = z.infer<typeof CategorySchema>;
export type ListCategoriesQuery = z.infer<typeof ListCategoriesQuerySchema>;
export type ListCategoriesResponse = z.infer<
  typeof ListCategoriesResponseSchema
>;
