import { coreAssistantMessageSchema, coreToolMessageSchema } from "ai";
import { z } from "zod";
import { AssistantSchema } from "./Assistant";
import { CategoryBucket } from "./Category";
import { IdentitySchema } from "./Identity";
import { SortOrder } from "./Pagination";

export { coreAssistantMessageSchema, coreToolMessageSchema } from "ai";

/**
 * TODO: Move this to a common set of tools, to use with Facts and Insights.
 */
export enum ConvoEntryProvenance {
  User = "user",
  Assistant = "assistant",
  UserEditedResult = "user-edited-result",
  CoreTool = "tool",
}

export enum ConvoSource {
  BuildBlockAssistant = "build-block-assistant",
  CreateAsset = "create-asset",
  CreateAssetAssistant = "create-asset-assistant",
  Ideas = "ideas",
  Dashboard = "dashboard",
  WorkspaceAssistant = "workspace-assistant",
  SalesSummary = "sales-summary",
  SitePerformanceSummary = "site-performance-summary",
}

export const assistantsSources = [
  ConvoSource.BuildBlockAssistant,
  ConvoSource.CreateAssetAssistant,
  ConvoSource.Ideas,
  ConvoSource.Dashboard,
] as const;

const ConvoEntryProvenanceSchema = z.object({
  source: z.nativeEnum(ConvoEntryProvenance),
  generator: z.string(),
});

export enum ConvoEntryType {
  /**
   * A block of new content, e.g the first message in a convo
   */
  Initial = "initial",

  /**
   * A refinement over a previous entry -- e.g the user edits their original prompt.
   *
   * This entry still includes the full prompt in that case, but knowing it's a refinement
   * we can display it differently, can collapse it, etc.
   */
  Refinement = "refinement",

  /**
   * An chat message entry -- e.g the user adds a new prompt to an existing convo with an assistant.
   */
  Prompt = "prompt",

  /**
   * An entry with a result, which may or may not be the final result in a convo. Generally
   * comes from a generator, but doesn't necesarily have to.
   */
  Result = "result",
}

export const ConvoEntryContentSchema = z.object({
  entryType: z.nativeEnum(ConvoEntryType),
  assistantId: AssistantSchema.shape.id.default("general-assistant").optional(),
  provenance: ConvoEntryProvenanceSchema.optional(),

  coreMessages: z
    .array(z.union([coreAssistantMessageSchema, coreToolMessageSchema]))
    .optional(),

  /**
   * Specifies formatting options for this content - currently we only
   * have text, but later we may want to process facts differently (e.g
   * earnings as specific currency)
   */
  format: z
    .discriminatedUnion("type", [
      // Covers both plain text, and eventually rich text (e.g json markup)
      z.object({
        type: z.literal("text"),
      }),
    ])
    .default({
      type: "text",
    }),

  plainText: z.string().optional(),
});

export const ConvoConfigurationSchema = z.object({
  // Deprecated:
  assistant: z.string().optional(),

  assistantId: AssistantSchema.shape.id.default("general-assistant").optional(),
  bucket: z.nativeEnum(CategoryBucket).optional(),
  isTitleGenerated: z.boolean().optional(),
  // Any source reference to identity from where the chat assistant was originated from (e.g. for a block, the blockPath, for a created asset, the asset convoId, ...)
  sourceRef: z.string().optional(),
});

export const ConvoEntrySchema = z.object({
  id: z.string().uuid(),
  content: ConvoEntryContentSchema,
  creatorId: z.string().uuid().nullable(),
  createdAt: z.date(),
  convoId: z.string().uuid(),
});

export const ConvoSchema = z.object({
  id: z.string().uuid(),
  creatorId: z.string().uuid(),
  title: z.string().nullable(),
  private: z.boolean(),
  source: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date().nullish(),
  configuration: ConvoConfigurationSchema,
  externalId: z.string().nullable(),

  /**
   * Computed via an extension (see db/extensions):
   */
  latestGenerator: ConvoEntryContentSchema.shape.provenance
    .unwrap()
    .shape.generator.optional(),
});

export const ConvoCreatorSchema = z.object({
  creator: IdentitySchema.pick({
    email: true,
    id: true,
    name: true,
  }),
});

export const CreateConvoSchema = ConvoSchema.pick({
  configuration: true,
  private: true,
  title: true,
  source: true,
});

export const ConvoWithCreatorSchema = ConvoSchema.merge(ConvoCreatorSchema);

export const ConvoWithRelated = ConvoSchema.merge(
  z.object({
    entries: z.array(ConvoEntrySchema),
  }),
).merge(ConvoCreatorSchema);

export const CreateConvoEntrySchema = ConvoEntrySchema.pick({
  content: true,
}).merge(
  z.object({
    convoId: ConvoEntrySchema.shape.convoId.nullable(),
    convoSource: ConvoSchema.shape.source.optional(),
    sourceRef: z.string().optional(),
  }),
);

export type ConvoSortField = keyof typeof ConvoSchema.shape;

export const sortFields = Object.keys(ConvoSchema.shape) as [ConvoSortField];

export const FilterConvoSchema = z.object({
  createdBy: z.union([z.literal("self"), z.string().uuid()]).optional(),
  inBucket: z.nativeEnum(CategoryBucket).optional(),
  source: z.array(z.nativeEnum(ConvoSource)).optional(),
  sourceRef: z.string().max(128).optional(),
  includeActiveEntry: z.boolean().optional(),
  skip: z.number().int().optional(),
  take: z.number().int().max(100).optional(),
  orderBy: z
    .object({
      sort: z.enum(sortFields),
      order: z.nativeEnum(SortOrder),
    })
    .optional(),
});

export const ConvoPromptSuggestionsSchema = z.object({
  ideas: z.array(z.string()),
});

export type CreateConvoEntry = z.infer<typeof CreateConvoEntrySchema>;
export type CreateConvo = z.infer<typeof CreateConvoSchema>;
export type ConvoConfiguration = z.infer<typeof ConvoConfigurationSchema>;
export type ConvoEntryContent = z.infer<typeof ConvoEntryContentSchema>;
export type Convo = z.infer<typeof ConvoSchema>;
export type ConvoWithCreator = z.infer<typeof ConvoWithCreatorSchema>;
export type ConvoWithRelated = z.infer<typeof ConvoWithRelated>;
export type ConvoEntry = z.infer<typeof ConvoEntrySchema>;
export type FilterConvoParams = z.infer<typeof FilterConvoSchema>;
export type ConvoPromptSuggestions = z.infer<
  typeof ConvoPromptSuggestionsSchema
>;

export function getActiveEntry(
  convo: ConvoWithRelated,
  { sort = SortOrder.ASC }: { sort?: SortOrder } = {},
) {
  const refinementOrInitialPromptEntries = convo.entries.filter(
    (e) =>
      e.content.entryType === ConvoEntryType.Initial ||
      e.content.entryType === ConvoEntryType.Refinement,
  );

  const resultEntries = convo.entries.filter(
    (e) => e.content.entryType === ConvoEntryType.Result,
  );

  const position = sort === "desc" ? 0 : -1;
  const activePromptEntry = refinementOrInitialPromptEntries.at(position);
  const activeResultEntry = resultEntries.at(position);

  return {
    prompt: activePromptEntry,
    result: activeResultEntry,
  };
}
