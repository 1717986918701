import { z } from "zod";

/**
 * Should contain all active Posthog events, used to capture user behavior.
 * Format: <action>_<category>_<object>
 * - action: verb in past tense to describe what happened, e.g. clicked, viewed, logged, updated, created, deleted...
 * - category: context the event took place in, e.g. signup, block, project, ...
 * - object (optional): noun that refers to the component or location the event took, e.g. continue_button, payment_link, ...
 */
export enum EventName {
  // Navigation events
  ViewedPage = "viewed_page",
  LeftPage = "left_page",
  ClickedNavAddProjectButton = "clicked_nav_add_project_button",
  // Auth events
  ClickedSignupContinueButton = "clicked_signup_continue_button",
  ClickedSignupButton = "clicked_signup_button",
  ClickedSignupGoogleButton = "clicked_signup_google_button",
  ClickedLoginButton = "clicked_login_button",
  ClickedLoginGoogleButton = "clicked_login_google_button",
  ClickedLoginMagicLinkButton = "clicked_login_magic_link_button",
  ClickedLoginSendMagicLinkButton = "clicked_login_send_magic_link_button",
  ClickedConfirmActionButton = "clicked_confirm_action_button",
  LoggedIn = "logged_in",
  LoggedOut = "logged_out",
  // Subscription events
  ClickedSubscriptionSelectPlanButton = "clicked_subscription_select_plan_button",
  ViewedSubscriptionPaymentPage = "viewed_subscription_payment_page",
  ViewedSubscriptionRenewalModal = "viewed_subscription_renewal_modal",
  ClickedSubscriptionRenewalButton = "clicked_subscription_renewal_button",
  CreatedCustomer = "created_customer",
  CompletedSubscriptionCheckoutSession = "completed_subscription_checkout_session",
  CreatedSubscription = "created_subscription",
  RenewedSubscription = "renewed_subscription",
  CanceledSubscription = "canceled_subscription",
  SetSubscriptionCancelation = "set_subscription_cancelation",
  UnsetSubscriptionCancelation = "unset_subscription_cancelation",
  PausedSubscription = "paused_subscription",
  ResumedSubscription = "resumed_subscription",
  ClickedSubscriptionUpdateItemButton = "clicked_subscription_update_item_button",
  UpdatedSubscriptionItem = "updated_subscription_item",
  // Knowledge events
  CreatedBlock = "created_block",
  UpdatedBlock = "updated_block",
  ClickedBlockHistoryButton = "clicked_block_history_button",
  RevertedBlockHistory = "reverted_block_version",
  ClickedBlockRegenerateOptionsButton = "clicked_block_regenerate_options_button",
  ClickedBlockRegenerateCustomMenuItem = "clicked_block_regenerate_custom_menu_item",
  RegeneratedBlock = "regenerated_block",
  ClickedKnowledgeEditStacksButton = "clicked_knowledge_edit_stacks_button",
  ClickedKnowledgeExploreStacksButton = "clicked_knowledge_explore_stacks_button",
  // Asset events
  ClickedAssetSuggestion = "clicked_asset_suggestion",
  CreatedAsset = "created_asset",
  UpdatedAssetResult = "updated_asset_result",
  UpdatedAssetInstructions = "updated_asset_instructions",
  DeletedAsset = "deleted_asset",
  // Settings events
  ToggledFeature = "toggled_feature",
  ClickedSettingsAddProjectButton = "clicked_settings_add_project_button",
  ClickedSettingsAddUserButton = "clicked_settings_add_user_button",
  ClickedSettingsInviteUserButton = "clicked_settings_invite_user_button",
  ClickedSettingsDeleteUserMenuItem = "clicked_settings_delete_user_menu_item",
  ClickedSettingsUpdateUserRoleMenuItem = "clicked_settings_update_user_role_menu_item",
  ClickedSettingsJoinReferralButton = "clicked_settings_join_referral_button",
  ClickedSettingsAddApiKeyButton = "clicked_settings_add_api_key_button",
  ClickedSettingsResetApiKeyButton = "clicked_settings_reset_api_key_button",
  ClickedSettingsConfirmResetApiKeyButton = "clicked_settings_confirm_reset_api_key_button",
  ClickedSettingsDeleteApiKeyButton = "clicked_settings_delete_api_key_button",
  ClickedSettingsConfirmDeleteApiKeyButton = "clicked_settings_confirm_delete_api_key_button",
  ClickedSettingsTransferButton = "clicked_settings_transfer_button",
  ClickedSettingsConfirmTransferButton = "clicked_settings_confirm_transfer_button",
  // Project events
  CreatedProject = "created_project",
  UpdatedProject = "updated_project",
  // User events
  UpdatedUserProfile = "updated_user_profile",
  UpdatedUserRole = "updated_user_role",
  CreatedUser = "created_user",
  DeletedUser = "deleted_user",
  // Onboarding events
  ViewedOnboardingVideo = "viewed_onboarding_video",
  ClosedOnboardingVideo = "closed_onboarding_video",
  ClickedOnboardingLearnButton = "clicked_onboarding_learn_button",
  ClosedOnboardingLearnContent = "closed_onboarding_learn_content",
  // Assistant events
  ClickedDocumentViewFilesButton = "clicked_document_view_files_button",
  ClickedDocumentUploadFileButton = "clicked_document_upload_file_button",
  ClosedDocumentUploadFileModal = "closed_document_upload_file_modal",
  // Task events
  ClickedTaskAddButton = "clicked_task_add_button",
  CreatedTask = "created_task",
  UpdatedTask = "updated_task",
  DeletedTask = "deleted_task",
  // Connection events
  CreatedConnection = "created_connection",
  UpdatedConnection = "updated_connection",
  DeletedConnection = "deleted_connection",
  // Note events
  CreatedNote = "created_note",
  UpdatedNote = "updated_note",
  DeletedNote = "deleted_note",
}

export enum SubscriptionType {
  None = "None",
  Trial = "Trial",
  Paid = "Paid",
}

export enum EventSource {
  ActiveCampaign = "active-campaign",
  Posthog = "posthog",
}

export const allEventSources = Object.values(EventSource);

const EventPropertiesSchema = z.record(z.string(), z.unknown());

export const TrackEventPayloadSchema = z.object({
  eventName: z.nativeEnum(EventName),
  properties: EventPropertiesSchema,
  sources: z.array(z.nativeEnum(EventSource)).optional(),
});
export type TrackEventPayload = z.infer<typeof TrackEventPayloadSchema>;
