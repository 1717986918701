import {
  Anchor,
  Button,
  Card,
  Center,
  Loader,
  Stack,
  Text,
} from "@mantine/core";
import { EventName } from "core";
import { useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { parse } from "tldts";
import { Page } from "../../components/Page";
import { NoanLogo } from "../../components/icons/NoanLogo.svg";
import { useTrackEvent } from "../../utils/useTrackEvent";

enum ConfirmActionType {
  ConfirmSignup = "confirm-signup",
  InviteUser = "invite",
  MagicLink = "otp",
  ChangeEmail = "change-email",
  ResetPassword = "reset-pw",
}

function isValidConfirmationUrl(rawUrl: string | null): rawUrl is string {
  if (!rawUrl) return false;

  try {
    const url = parse(rawUrl, {
      mixedInputs: false,
      allowPrivateDomains: false,
    });

    return url.domain === "supabase.co";
  } catch (_err) {
    return false;
  }
}

function isValidConfirmActionType(
  action: string | null,
): action is ConfirmActionType {
  return (
    (action &&
      Object.values(ConfirmActionType).includes(action as ConfirmActionType)) !=
    null
  );
}

function renderConfirmAction(action: ConfirmActionType) {
  switch (action) {
    case ConfirmActionType.ChangeEmail:
      return "Confirm email change";
    case ConfirmActionType.ConfirmSignup:
      return "Confirm your email";
    case ConfirmActionType.InviteUser:
      return "Accept Invite";
    case ConfirmActionType.MagicLink:
      return "Login to NOAN";
    case ConfirmActionType.ResetPassword:
      return "Reset your password";
  }
}

const autoRedirectActions: ConfirmActionType[] = [ConfirmActionType.MagicLink];

export function ConfirmAction() {
  const [search] = useSearchParams();
  const confirmUrl = search.get("u");
  const action = search.get("a");

  const { track } = useTrackEvent();

  const isValid =
    isValidConfirmActionType(action) && isValidConfirmationUrl(confirmUrl);

  const autoRedirects = isValid && autoRedirectActions.includes(action);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (isValid && autoRedirects) {
      interval = setTimeout(() => window.location.assign(confirmUrl), 1000);
    }

    return () => interval && clearTimeout(interval);
  }, [isValid, confirmUrl, autoRedirects]);

  /**
   * Ensure the URL is a valid confirmation URL, so that we're not potentially opening up
   * a security hole with open redirects:
   */
  if (!isValid) {
    return <Navigate to="/" />;
  }

  function onClickUrl() {
    track(EventName.ClickedConfirmActionButton, {
      action,
      url: confirmUrl,
    });
  }

  return (
    <Page size="xs" containerSize="sm">
      <Card shadow="sm" mt="lg" py="xl">
        <Center>
          <NoanLogo />
        </Center>

        <Stack gap="md" align="center" mt="xl">
          {autoRedirects ? (
            <>
              <Loader />
              <Text>You will be automatically redirected. </Text>
              <Anchor
                component={Link}
                to={confirmUrl ?? "/"}
                onClick={onClickUrl}
                size="xs"
                c="dimmed"
              >
                Click here if you are not redirected.
              </Anchor>
            </>
          ) : (
            <Button
              component={Link}
              to={confirmUrl ?? "/"}
              onClick={onClickUrl}
              size="md"
            >
              {renderConfirmAction(action)}
            </Button>
          )}
        </Stack>
      </Card>

      <Text size="sm" mt="lg" c="dimmed">
        You are seeing this page because you followed a link from an email sent
        to you by NOAN. If you did not request this action, please exit this
        page and ignore the email.
      </Text>
    </Page>
  );
}
