import type Stripe from "stripe";
import { z } from "zod";
import { type FactContentAmountItem } from "./Fact";

/**
 * Lookup Keys set in Stripe prices to facilitate migrating subscriptions without necessary changes in the code.
 * With these keys, it's enough to create a new price in Stripe and only migrate the lookup key from the previous to the new price.
 */
export enum PriceLookupKey {
  /** Basic subscription */
  BasicMonthly = "basic_monthly",
  BasicSeatMonthly = "basic_seat_monthly",
  BasicProjectMonthly = "basic_project_monthly",

  /** @deprecated - Only keeping for compatibility with users that are still active with those obsolete subscriptions */
  BasicYearly = "basic_yearly",
  /** @deprecated */
  BasicSeatYearly = "basic_seat_yearly",
  /** @deprecated */
  BasicProjectYearly = "basic_project_yearly",

  /** Scale subscription */
  /** @deprecated */
  ScaleMonthly = "scale_monthly",
  /** @deprecated */
  ScaleSeatMonthly = "scale_seat_monthly",
  /** @deprecated */
  ScaleProjectMonthly = "scale_project_monthly",
  /** @deprecated */
  ScaleYearly = "scale_yearly",
  /** @deprecated */
  ScaleSeatYearly = "scale_seat_yearly",
  /** @deprecated */
  ScaleProjectYearly = "scale_project_yearly",
}

export const baseLookupKeys = [
  PriceLookupKey.BasicMonthly,
  PriceLookupKey.BasicYearly,
  PriceLookupKey.ScaleMonthly,
  PriceLookupKey.ScaleYearly,
];

export type BaseLookupKey = (typeof baseLookupKeys)[number];

export enum SalesBlockPathKeys {
  Sales = "stripe/sales",
  Net = "net",
  Gross = "gross",
  PaymentsSucceeded = "payments-succeeded",
  PaymentsUncaptured = "payments-uncaptured",
  PaymentsRefunded = "payments-refunded",
  PaymentsFailed = "payments-failed",
  Customers = "customers",
  Churns = "churns",
}

export enum StripeCacheKeys {
  PromoCodes = "promo-codes",
}

export enum SubscriptionItemType {
  Seat = "seat",
  Project = "project",
}

export const validStatuses: Stripe.Subscription["status"][] = [
  "active",
  "trialing",
  "paused",
];

type PeriodSalesItem = FactContentAmountItem & { net?: number };

export type PeriodSales = {
  grossVolume: number;
  netVolume: number;
  items: PeriodSalesItem[];
  previousPeriodItems: PeriodSalesItem[];
};

export const transactionTypesImpactingNet: Stripe.BalanceTransaction.Type[] = [
  "charge",
  "refund",
  "adjustment",
  "tax_fee",
];

const StripeGetAccessTokenResponseSchema = z.object({
  access_token: z.string(),
  livemode: z.boolean(),
  refresh_token: z.string(),
  scope: z.string(),
  stripe_publishable_key: z.string(),
  stripe_user_id: z.string(),
  token_type: z.string(),
});

const StripeDeauthorizeResponseSchema = z.object({
  stripe_user_id: z.string(),
});

export type StripeGetAccessTokenResponse = z.infer<
  typeof StripeGetAccessTokenResponseSchema
>;
export type StripeDeauthorizeResponse = z.infer<
  typeof StripeDeauthorizeResponseSchema
>;
