type NoanApplicationErrorContext = Record<string, unknown> | string;

export enum NoanError {
  ExternalApiError = "Unknown third-party API error",
  Invariant = "Invariant error",
  InvalidPromptMissingUserItem = "Invalid generator prompt - missing user item",
  InvalidConnector = "Invalid connector",
  MissingIdentifier = "Missing a valid resource identifier",
  MissingStripeCustomerId = "Account does not have a valid stripe customer ID",
  MissingPermission = "Account does not have permission to complete this action",
  MissingServiceIdentity = "Unable to resolve service identity for job",
  LLMExpectationsFailure = "LLM response does not meet expectations",
  ResourceNotFound = "Resource not found",
  ResourceNotAllowed = "Access to resource not allowed",
  MultipleResourcesFound = "Multiple resources found",
  NotLoggedIn = "User not logged in",
}

export class NoanApplicationError extends Error {
  constructor(
    public readonly errorType: NoanError,
    public readonly ctx: NoanApplicationErrorContext,
  ) {
    super(`${errorType}${typeof ctx === "string" ? `: ${ctx}` : ""}`);
    this.name = `${errorType}`;
  }

  toString() {
    return `${this.errorType}: ${JSON.stringify(this.ctx)}`;
  }
}

export function generateNoanError(
  errorType: NoanError,
  additionalContext: NoanApplicationErrorContext = {},
) {
  return new NoanApplicationError(errorType, additionalContext);
}

export function isNoanError(error: Error): error is NoanApplicationError {
  return (error as NoanApplicationError).errorType != null;
}
