import { AppShell } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { EventName, isNoanError, NoanError } from "core";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import { useOptionalAuthContext } from "../../auth/useAuthContext";
import { useIdentity } from "../../auth/useIdentity";
import { isDeployed } from "../../config";
import { useUpdateReferral } from "../../utils/useAccountMutation";
import { useClientMode } from "../../utils/useClientMode";
import { useOrganization } from "../../utils/useOrganization";
import { getUserProperties, useTrackEvent } from "../../utils/useTrackEvent";
import { ZedIndex } from "../../utils/zedIndex";
import { FullPageLoader } from "../FullPageLoader";
import { ReaderPanelContextProvider } from "../ReaderPanel/ReaderPanelContext";
import { useTheme } from "../ThemeProvider/useTheme";
import classes from "./Layout.module.css";
import {
  isSubscribePage,
  LayoutVariant,
  topHeaderHeights,
} from "./layoutConfig";
import { SidebarLayout } from "./SidebarLayout";

function ThemedAuthedLayout({ children }: React.PropsWithChildren) {
  const { organization } = useOrganization();
  const { isClient } = useClientMode();
  const { updateColors, resetTheme } = useTheme();
  const { pathname } = useLocation();

  /**
   * Sets the app colors for client mode.
   */
  useEffect(() => {
    const orgTheme = organization?.settings.clientMode;

    if (
      // Some pages are accessible by owner as a preview of client mode,
      // e.g onboarding form, so we also want to apply the client mode branding
      // settings for those pages.
      (isClient || pathname === "/client/welcome") &&
      orgTheme?.colorPrimary &&
      orgTheme?.colorSecondary
    ) {
      updateColors([
        {
          colorHex: orgTheme?.colorPrimary,
          colorName: "clientModePrimaryColor",
          generateColorPalette: true,
          overrideColorName: "primaryColor",
        },
        {
          colorHex: orgTheme?.colorSecondary,
          colorName: "clientModeSecondaryColor",
          generateColorPalette: true,
          overrideColorName: "secondaryColor",
        },
      ]);
    } else {
      resetTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient, organization?.settings.clientMode, pathname]);

  return children;
}

export function AuthedLayout({ variant }: { variant?: LayoutVariant }) {
  const posthog = usePostHog();
  const { pathname } = useLocation();
  const [isFirstSessionLogin, setIsFirstSessionLogin] = useLocalStorage({
    key: "noan-first-session-login",
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  const {
    user,
    signOut,
    isLoadingUser,
    authError,
    jwtBearerToken,
    mustSetPassword,
    hasThirdPartyProvider,
    mustCompletePostSignup,
  } = useOptionalAuthContext();
  const {
    identity,
    isActive,
    isCustomer,
    isPaying,
    accountStatus,
    isLoading: isLoadingIdentity,
    hasInsightsAccess,
    isError: identityError,
    mustCompleteOnboarding,
    hasReferral,
  } = useIdentity();
  const { updateReferral } = useUpdateReferral();
  const { track } = useTrackEvent();

  const isSubscriptionRequired = !isLoadingIdentity && !isActive;

  useEffect(() => {
    /**
     * We're not tracking the event in the supabase.auth.onAuthStateChange in supabase.ts file directly mainly because
     * there are a couple of not initialized yet: react-query client, ...
     * The supabase SIGNED_IN event can be triggered multiple times within the same session (e.g. when refreshing a page).
     * To make sure we only send one logged_in user event per session, we store the very first time the event is being fired.
     */
    if (isFirstSessionLogin && user != null) {
      setIsFirstSessionLogin(false);
      track(EventName.LoggedIn);
    }
  }, [isFirstSessionLogin, setIsFirstSessionLogin, track, user]);

  /**
   * Once we have user information, update third-party tools:
   */
  useEffect(() => {
    if (isDeployed && user != null) {
      try {
        if (window.tidioChatApi) {
          window.tidioChatApi.setVisitorData({
            distinct_id: user.id,
            email: user.email,
          });
        }

        posthog.identify(`${user.email}#${user.id.slice(0, 6)}`, {
          ...getUserProperties(user),
        });

        // Save the Rewardful referral id to the account to add it later in the
        // Stripe customer metadata to sync the leads and conversions after checkout.
        if (window.Rewardful?.referral && !isLoadingIdentity && !hasReferral) {
          updateReferral({
            referral: window.Rewardful.referral,
          });
        }
      } catch (err) {
        console.warn("Error while identifying user");
        console.error(err);
      }
    }
  }, [hasReferral, isLoadingIdentity, posthog, updateReferral, user]);

  if (identityError) {
    signOut();
    return null;
  }

  if (mustCompletePostSignup && pathname !== "/join") {
    // User must complete post-signup tasks
    return <Navigate to="/join" />;
  } else if (authError != null) {
    const errorType =
      isNoanError(authError) && authError.errorType === NoanError.NotLoggedIn
        ? "no-auth"
        : "auth-error";

    // Unable to fetch auth for some reason - might be missing user.
    return <Navigate to={`/login?reason=${errorType}`} />;
  } else if (isLoadingUser || isLoadingIdentity) {
    // Is loading user or identity data, and there's no cached identity data available
    return <FullPageLoader />;
  } else if (isSubscriptionRequired && !isSubscribePage(pathname)) {
    return <Navigate to={"/subscribe"} />;
  } else if (isActive && mustCompleteOnboarding && pathname !== "/welcome") {
    return <Navigate to={"/welcome"} />;
  } else if (
    (user == null && !isLoadingUser) ||
    (identity == null && !isLoadingIdentity)
  ) {
    return <Navigate to="/login?reason=no-auth" />;
  }

  return (
    <AuthContext.Provider
      value={{
        jwtBearerToken,
        user: user!,
        identity: identity!,
        isAccountActive: isActive,
        isCustomer,
        isPayingCustomer: isPaying,
        accountStatus,
        isLoadingIdentity,
        isLoadingUser,
        authError,
        mustSetPassword,
        hasThirdPartyProvider,
        mustCompletePostSignup,
        mustCompleteOnboarding,
        signOut,
        hasInsightsAccess,
      }}
    >
      <ThemedAuthedLayout>
        <Layout variant={variant} />
      </ThemedAuthedLayout>
    </AuthContext.Provider>
  );
}

export interface LayoutProps {
  variant?: LayoutVariant;
  topHeader?: JSX.Element;
  withPadding?: boolean;
}

function DefaultLayout({ topHeader, withPadding }: LayoutProps) {
  const shellPadding = { base: "sm", md: "xl" };
  const mainPadding = {
    base: topHeader ? topHeaderHeights.mobile : 0,
    xs: topHeader ? topHeaderHeights.desktop : 0,
  };

  return (
    <ReaderPanelContextProvider>
      <AppShell
        p={withPadding ? shellPadding : undefined}
        bg="var(--mantine-color-body)"
      >
        <AppShell.Header zIndex={ZedIndex.Header} withBorder={false}>
          {topHeader}
        </AppShell.Header>

        <AppShell.Main pt={mainPadding}>
          <Outlet />
          <ScrollRestoration getKey={(location) => location.pathname} />
        </AppShell.Main>
      </AppShell>
    </ReaderPanelContextProvider>
  );
}

export function MinimalLayout() {
  return (
    <div className={classes.minimalRoot}>
      <Outlet />
    </div>
  );
}

export function Layout({ variant, topHeader, withPadding }: LayoutProps) {
  /**
   * Redirect beta.getnoan.com to app.getnoan.com in all cases, preserving
   * path information.
   */
  useEffect(() => {
    const { host, pathname, search } = document.location;

    if (host === "beta.getnoan.com") {
      const newLocation = new URL(`https://app.getnoan.com${pathname}`);
      newLocation.search = search;
      // document.location.assign(newLocation);
    }
  }, []);

  const content = useMemo(() => {
    if (variant === "sidebar") {
      return <SidebarLayout topHeader={topHeader} />;
    } else if (variant === "minimal") {
      return <MinimalLayout />;
    } else {
      return (
        <DefaultLayout
          withPadding={withPadding}
          variant={variant}
          topHeader={topHeader}
        />
      );
    }
  }, [topHeader, variant, withPadding]);

  return content;
}
