export type LayoutVariant = "sidebar" | "minimal";

export const topHeaderHeights = {
  mobile: 80,
  desktop: 30,
  minimal: 0,
} as const;

export function isSubscribePage(pathname: string) {
  return pathname === "/subscribe";
}
