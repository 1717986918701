import { z } from "zod";
import { IdentityEmbedSchema } from "./Identity";

export const TaskSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  details: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  removedAt: z.date().nullable(),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  private: z.boolean(),
  completed: z.boolean(),
  updatorId: z.string().nullable(),
  createdByAssistant: z.boolean(),
  creator: IdentityEmbedSchema,
  externalId: z.string().nullable(),
});

export const CreateTaskSchema = z.object({
  externalId: TaskSchema.shape.externalId.optional(),
  title: TaskSchema.shape.title,
  details: TaskSchema.shape.details.optional(),
  creatorEmail: IdentityEmbedSchema.shape.email.optional(),
});

export const CreateTaskResponseSchema = z.object({
  task: z.object({
    id: TaskSchema.shape.id,
    title: TaskSchema.shape.title,
    details: TaskSchema.shape.details.nullable(),
    private: TaskSchema.shape.private,
    createdAt: TaskSchema.shape.createdAt,
  }),
});

export type Task = z.infer<typeof TaskSchema>;
export type CreateTaskResponse = z.infer<typeof CreateTaskResponseSchema>;
