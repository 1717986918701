import { User } from "@supabase/supabase-js";
import { cleanObject, EventName, EventSource } from "core";
import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { useOptionalAuthContext } from "../auth/useAuthContext";
import { isDeployed } from "../config";
import { trpc } from "./trpc";

function debugEvent(
  eventName: EventName,
  properties?: Record<string, unknown>,
) {
  // eslint-disable-next-line no-console
  console.log(
    `%c track %c ${eventName}`,
    "color: orange",
    "color: cyan; font-weight: bold",
    properties,
  );
}

enum UTMParam {
  Campaign = "utm_campaign",
  Medium = "utm_medium",
  Source = "utm_source",
}

function getUtmProperties() {
  const search = new URLSearchParams(window.location.search);

  return {
    [UTMParam.Campaign]: search.get(UTMParam.Campaign),
    [UTMParam.Medium]: search.get(UTMParam.Medium),
    [UTMParam.Source]: search.get(UTMParam.Source),
  };
}

function useTrackEventAC() {
  const { mutate } = trpc.event.track.useMutation();

  const trackEventAC = ({
    eventName,
    eventData,
  }: {
    eventName: EventName;
    eventData: Record<string, unknown>;
  }) =>
    mutate({
      eventName,
      properties: eventData,
      sources: [EventSource.ActiveCampaign],
    });

  return {
    trackEventAC,
  };
}

export function getUserProperties(user?: User) {
  return user
    ? {
        id: user.id,
        email: user.email,
      }
    : {};
}

/**
 * Capture user event into PostHog
 */
export function useTrackEvent() {
  const posthog = usePostHog();

  const { user } = useOptionalAuthContext();
  const { trackEventAC } = useTrackEventAC();

  const track = useCallback(
    (
      eventName: EventName,
      properties?: Record<string, unknown>,
      { skipAC }: { skipAC: boolean } = { skipAC: false },
    ) => {
      const eventData = cleanObject({
        ...properties,
        ...getUtmProperties(),
        ...getUserProperties(user),
      });

      if (!isDeployed) {
        debugEvent(eventName, eventData);
        return;
      }

      posthog.capture(eventName, eventData);
      if (!skipAC) {
        trackEventAC({ eventName, eventData });
      }
    },
    [posthog, trackEventAC, user],
  );

  return {
    track,
  };
}
