import {
  Box,
  Button,
  Card,
  Center,
  ScrollAreaAutosize,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconError404,
  IconFileAlert,
  IconLockAccessOff,
} from "@tabler/icons-react";
import type { ErrorCode } from "core";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { isDeployed } from "../config";
import { captureAndReportError } from "../sentry";
import type { TablerIcon } from "../utils/icons";

interface ErrorWithStatus {
  status?: number;
}

const errorCodeToTitle: Record<
  ErrorCode,
  {
    title: string;
    description: string;
    icon: TablerIcon;
  }
> = {
  403: {
    title: "No Access",
    description: "You don't appear to have permission to access this page",
    icon: IconLockAccessOff,
  },
  404: {
    title: "Page not found",
    description: "The page you are looking for does not exist.",
    icon: IconError404,
  },
  500: {
    title: "Uh-oh, that wasn't meant to happen",
    description:
      "Refresh this page and you should end up where you expected to be. We're shipping new features constantly and sometimes that creates glitches - we're sorry!",
    icon: IconFileAlert,
  },
} as const;

function isError(err: unknown): err is Error {
  return err instanceof Error;
}

/**
 * Resolves an arbitrary number code into a valid ErrorCode,
 * converting to a masked code if necessary (e.g 403 -> 500 if
 * no 403 handler is implemented)
 */
function asErrorCode(code: number): ErrorCode {
  switch (code) {
    case 404:
      return 404;
    default:
      return 500;
  }
}

export function ErrorPage({
  code,
  title,
  description,
}: {
  code?: ErrorCode;
  title?: string;
  description?: string;
}) {
  const error = useRouteError();
  const resolvedCode = asErrorCode(
    code ?? (error as ErrorWithStatus)?.status ?? 500,
  );
  const Icon = errorCodeToTitle[resolvedCode].icon;

  useEffect(() => {
    if (isError(error)) {
      console.error(error);
      captureAndReportError(error);
    }
  }, [error]);

  function onGoBackToMain() {
    /**
     * Forces a full page refresh, in case the redirection to the error page
     * was due to trying to access content from the browser old cache after a
     * deployment.
     */
    window.location.href = "/";
  }

  return (
    <Center component={Stack} align="center" p="xl" ta="center">
      <ThemeIcon size={180} variant="transparent" color="dimmed">
        <Icon size={180} stroke="none" />
      </ThemeIcon>
      <Title fw="bold" maw={600}>
        {title ?? errorCodeToTitle[resolvedCode].title}
      </Title>
      <Text c="gray.6" maw={600}>
        {description ?? errorCodeToTitle[resolvedCode].description}
      </Text>

      {!isDeployed && isError(error) && (
        <Card miw={500} maw={800}>
          <ScrollAreaAutosize scrollbars="xy">
            <pre>{error.stack}</pre>
          </ScrollAreaAutosize>
        </Card>
      )}

      <Box mt="md">
        <Button variant="light" onClick={onGoBackToMain}>
          Return to main page
        </Button>
      </Box>
    </Center>
  );
}
