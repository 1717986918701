import {
  Button,
  Divider,
  Drawer,
  Group,
  Loader,
  Skeleton,
  Tabs,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useStoryblok } from "@storyblok/react";
import StoryblokStory from "@storyblok/react/story";
import { IconBook, IconExternalLink } from "@tabler/icons-react";
import { EventName } from "core";
import { createContext, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import type { OnlyChildrenProps } from "../../utils/misc";
import { slugToIntelPath } from "../../utils/storyblokHelpers";
import { useTrackEvent } from "../../utils/useTrackEvent";
import { ZedIndex } from "../../utils/zedIndex";

export interface ReaderPanelStory {
  title: string;
  slug: string;
}

export interface OpenReaderPanelStoryArgs {
  stories: Record<string, ReaderPanelStory>;
  defaultStoryId?: string;
}

interface ReaderPanelContextProps {
  /** Opens the reader with the given story configuration */
  openReaderPanelWithStory: (args: OpenReaderPanelStoryArgs) => void;

  /** Opens the reader with whatever's there already, IF there's something to show */
  openReaderMaybe: () => void;

  /** Closes the reader panel */
  closeReader: () => void;
}

export const ReaderPanelContext = createContext<ReaderPanelContextProps | null>(
  null,
);

function StoryView({
  stories,
  isOpen,
  onClose,
  defaultStoryId,
}: {
  stories: Record<string, ReaderPanelStory>;
  defaultStoryId?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [activeStoryId, setActiveStoryId] = useState<string>(
    defaultStoryId ?? Object.keys(stories).at(0)!,
  );
  const activeStory = stories[activeStoryId];
  const blok = useStoryblok(activeStory.slug, {
    version: "published",
  });
  const fullSlug = blok.full_slug;
  const showExternalLink =
    fullSlug != null &&
    (fullSlug.startsWith("intel/") || fullSlug.startsWith("content/"));

  return (
    <Drawer
      opened={isOpen}
      size={"xl"}
      onClose={onClose}
      title={
        <Group mih={35}>
          <Group gap="xs">
            <ThemeIcon size="md" variant="light">
              <IconBook />
            </ThemeIcon>
            <Title order={4}>{activeStory.title}</Title>
          </Group>
          {showExternalLink && (
            <Button
              size="xs"
              ml="md"
              leftSection={<IconExternalLink size={14} />}
              component={Link}
              to={slugToIntelPath(fullSlug)}
              target="_blank"
              variant="default"
            >
              Read in The Intel
            </Button>
          )}
        </Group>
      }
      position="right"
      zIndex={ZedIndex.LearnDrawer}
    >
      {!blok?.content ? (
        <>
          <Skeleton height={40} mb="md" />
          <Divider mb="lg" />
          <Skeleton height={400} />
        </>
      ) : (
        <Tabs
          mb="xl"
          keepMounted={false}
          onChange={(tabId) => setActiveStoryId(tabId!)}
          defaultValue={activeStoryId}
        >
          <Tabs.List
            display={Object.keys(stories).length > 1 ? undefined : "none"}
          >
            {Object.entries(stories).map(([storyId, story]) => (
              <Tabs.Tab key={storyId} value={storyId}>
                {story.title}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {Object.entries(stories).map(([storyId, story]) => (
            <Tabs.Panel value={storyId} key={storyId} pt="md">
              {!blok && <Loader />}
              {fullSlug === story.slug && <StoryblokStory story={blok} />}
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Drawer>
  );
}

export function ReaderPanelContextProvider({ children }: OnlyChildrenProps) {
  const [readerOpen, { close: closeReader, open: openReader }] =
    useDisclosure();

  const [currentStories, setCurrentStories] = useState<
    Record<string, ReaderPanelStory> | undefined
  >();

  const [defaultStoryId, setDefaultStoryId] = useState<string | undefined>();

  const { track } = useTrackEvent();

  const openReaderPanelWithStory = useCallback(
    ({ stories, defaultStoryId }: OpenReaderPanelStoryArgs) => {
      setCurrentStories(stories);
      setDefaultStoryId(defaultStoryId);

      openReader();
    },
    [openReader],
  );

  const openReaderMaybe = useCallback(() => {
    if (currentStories != null) {
      openReader();
    }
  }, [currentStories, openReader]);

  function onCloseStory() {
    setCurrentStories(undefined);
    closeReader();
    track(EventName.ClosedOnboardingLearnContent, {
      contentSlug: currentStories
        ? Object.values(currentStories)
            .map((sto) => sto.slug)
            .join(", ")
        : undefined,
    });
  }

  return (
    <ReaderPanelContext.Provider
      value={{
        openReaderPanelWithStory,
        openReaderMaybe,
        closeReader,
      }}
    >
      {children}

      {currentStories && (
        <StoryView
          isOpen={readerOpen}
          onClose={onCloseStory}
          stories={currentStories}
          defaultStoryId={defaultStoryId}
        />
      )}
    </ReaderPanelContext.Provider>
  );
}
