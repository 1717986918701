import { z } from "zod";

export const ACTIVE_CAMPAIGN_ONBOARDING_LIST_ID = 21;

export enum ActiveCampaignStatus {
  Active = 1,
  Unconfirmed = 0,
  Unsubscribed = 2,
  Bounced = 3,
}

export enum ActiveCampaignFieldIds {
  SubscriptionType = "9",
  JobTitle = "5",
  ProjectName = "4",
  BusinessIndustry = "11",
  UsingClientMode = "10",
}

const ActiveCampaignFieldValueSchema = z.object({
  field: z.string(),
  value: z.string(),
});

export type ActiveCampaignFieldValue = z.infer<
  typeof ActiveCampaignFieldValueSchema
>;

const ActiveCampaignContactSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.number().optional(),
});

const ActiveCampaignSyncContactSchema = z
  .object({
    fieldValues: z.array(ActiveCampaignFieldValueSchema).optional(),
  })
  .merge(
    ActiveCampaignContactSchema.pick({
      email: true,
      firstName: true,
      lastName: true,
      phone: true,
    }),
  );

export type ActiveCampaignSyncContact = z.infer<
  typeof ActiveCampaignSyncContactSchema
>;

const ActiveCampaignSyncContactResponseSchema = z.object({
  fieldValues: ActiveCampaignSyncContactSchema.shape.fieldValues,
  contact: ActiveCampaignContactSchema,
});

export type ActiveCampaignSyncContactResponse = z.infer<
  typeof ActiveCampaignSyncContactResponseSchema
>;

const ActiveCampaignAddContactToListSchema = z.object({
  contact: z.number(),
  list: z.number(),
  status: z.number(),
});

export type ActiveCampaignAddContactToList = z.infer<
  typeof ActiveCampaignAddContactToListSchema
>;

const ActiveCampaignAddContactToListResponseSchema = z.object({
  contactList: z
    .object({
      id: z.string(),
    })
    .merge(ActiveCampaignAddContactToListSchema),
  contacts: z.array(ActiveCampaignContactSchema).optional(),
});

export type ActiveCampaignAddContactToListResponse = z.infer<
  typeof ActiveCampaignAddContactToListResponseSchema
>;

const ActiveCampaignContactTagSchema = z.object({
  id: z.string(),
  contact: z.string(),
  tag: z.string(),
});

const ActiveCampaignAddTagToContactSchema = ActiveCampaignContactTagSchema.pick(
  {
    contact: true,
    tag: true,
  },
);

export type ActiveCampaignAddTagToContact = z.infer<
  typeof ActiveCampaignAddTagToContactSchema
>;

const ActiveCampaignAddTagToContactResponseSchema = z.object({
  contactTag: ActiveCampaignContactTagSchema,
});

export type ActiveCampaignAddTagToContactResponse = z.infer<
  typeof ActiveCampaignAddTagToContactResponseSchema
>;

const ActiveCampaignDeleteTagFromContactSchema = z.object({
  contactTagId: z.string(),
});

export type ActiveCampaignDeleteTagFromContact = z.infer<
  typeof ActiveCampaignDeleteTagFromContactSchema
>;

const ActiveCampaignDeleteTagFromContactResponseSchema = z.object({
  message: z.string().optional(),
});

export type ActiveCampaignDeleteTagFromContactResponse = z.infer<
  typeof ActiveCampaignDeleteTagFromContactResponseSchema
>;

const ActiveCampaignGetContactTagsSchema = z.object({
  contactId: z.string(),
});

export type ActiveCampaignGetContactTags = z.infer<
  typeof ActiveCampaignGetContactTagsSchema
>;

const ActiveCampaignGetContactTagsResponseSchema = z.object({
  contactTags: z.array(ActiveCampaignContactTagSchema),
});

export type ActiveCampaignGetContactTagsResponse = z.infer<
  typeof ActiveCampaignGetContactTagsResponseSchema
>;
