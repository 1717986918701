import {
  Avatar,
  Box,
  FloatingPosition,
  Group,
  Loader,
  Menu,
  ScrollAreaAutosize,
  Skeleton,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconChevronRight,
  IconPlus,
  IconUsersGroup,
} from "@tabler/icons-react";
import clsx from "clsx";
import { EventName, IdentityRole } from "core";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../auth/useAuthContext";
import { useIsGranted } from "../../auth/useIsGranted";
import { useOrganizationMutation } from "../../utils/useOrganizationMutation";
import { useOrganizations } from "../../utils/useOrganizations";
import { useTrackEvent } from "../../utils/useTrackEvent";
import classes from "./Layout.module.css";

function ProjectSwitcherItem({
  active = false,
  name,
  orgLogo,
  onSelectOrganization,
  rightSection,
}: {
  active?: boolean;
  name?: string;
  orgLogo?: string;
  onSelectOrganization?: () => void;
  rightSection?: JSX.Element;
}) {
  const showLogo = !!orgLogo;

  return (
    <Group
      flex={1}
      gap="xs"
      onClick={onSelectOrganization}
      w="100%"
      wrap="nowrap"
      className={clsx(active && classes.sidebarOrgSwitcherItemActive)}
    >
      {/* Set the icon color based on org name initials, if looking at the list of orgs */}
      <Avatar
        variant={active ? "filled" : "light"}
        color={active ? "var(--mantine-primary-color-3)" : "dimmed"}
        name={name}
        src={showLogo ? orgLogo : undefined}
        radius={showLogo ? "sm" : "xl"}
        classNames={{
          image: classes.sidebarOrgSwitcherLogo,
        }}
      >
        <IconUsersGroup size={20} />
      </Avatar>
      <Group
        className={classes.sidebarOrgSwitcherItemLabelAction}
        flex={1}
        wrap="nowrap"
        gap={"xs"}
      >
        <Box size="md" flex={1} pl="xs">
          {name ? (
            <Text {...(!active ? { truncate: true, maw: 180 } : {})}>
              {name}
            </Text>
          ) : (
            <Skeleton w={120} h={25} animate />
          )}
        </Box>
        {rightSection}
      </Group>
    </Group>
  );
}

interface ProjectSwitcherProps {
  menuPosition?: FloatingPosition;
  disableCreateProject?: boolean;
  hideIfNoOtherProjects?: boolean;
  switcherTarget?: JSX.Element;
}

export function ProjectSwitcher({
  menuPosition = "bottom-start",
  disableCreateProject = false,
  hideIfNoOtherProjects = false,
  switcherTarget,
}: ProjectSwitcherProps) {
  const navigate = useNavigate();
  const { data: projects } = useOrganizations();

  const { track } = useTrackEvent();
  const { identity } = useAuthContext();
  const { hasPermission: hasCreateProjectPermission } = useIsGranted({
    permission: "organization.create",
  });

  const { switchOrganization, isLoadingSwitchOrganization } =
    useOrganizationMutation({
      onSettledSwitchOrg: () => {
        navigate("/");
      },
    });

  const availableProjects = projects.filter(
    (o) => o.id !== identity.organizationId,
  );
  const activeProject = projects.find((o) => o.id === identity.organizationId);
  const hasMultipleProjects = availableProjects.length > 0;
  const canCreateProject = hasCreateProjectPermission && !disableCreateProject;
  const isSwitcherEnabled = hasMultipleProjects || canCreateProject;

  function onClickAddProject() {
    track(EventName.ClickedNavAddProjectButton);
    navigate("/account/project/general");
  }

  if (hideIfNoOtherProjects && !hasMultipleProjects) return null;

  return (
    <Menu
      position={menuPosition}
      offset={3}
      width={380}
      disabled={!isSwitcherEnabled || isLoadingSwitchOrganization}
      withArrow
      arrowPosition="side"
      arrowOffset={30}
      arrowSize={10}
    >
      <Menu.Target>
        {switcherTarget ?? (
          <Group
            w="100%"
            px="sm"
            py="xs"
            align="center"
            component={"button"}
            justify="flex-start"
            className={clsx([
              classes.sidebarOrgSwitcherButton,
              isSwitcherEnabled && classes.sidebarOrgSwitcherButtonEnabled,
            ])}
          >
            <ProjectSwitcherItem
              active
              name={activeProject?.name}
              rightSection={
                isSwitcherEnabled ? (
                  <ThemeIcon variant="transparent" color="dimmed">
                    {isLoadingSwitchOrganization ? (
                      <Loader size="sm" color="dimmed" />
                    ) : (
                      <IconChevronRight />
                    )}
                  </ThemeIcon>
                ) : undefined
              }
            />
          </Group>
        )}
      </Menu.Target>

      <Menu.Dropdown>
        <ScrollAreaAutosize
          mah={200}
          type="hover"
          scrollbars="y"
          scrollbarSize={4}
          offsetScrollbars
        >
          {canCreateProject && (
            <>
              <Menu.Item py={4} onClick={onClickAddProject}>
                <Group gap={2} justify="flex-end">
                  <ThemeIcon
                    className={classes.sidebarSwitcherAddProjectButton}
                    variant="transparent"
                  >
                    <IconPlus size={14} stroke={3} />
                  </ThemeIcon>
                  <Text size="xs" c="dimmed" fw="bold">
                    Add project
                  </Text>
                </Group>
              </Menu.Item>
              {hasMultipleProjects && <Menu.Divider />}
            </>
          )}
          {availableProjects.map((org) => (
            <Menu.Item key={org.id}>
              <ProjectSwitcherItem
                name={org.name}
                orgLogo={
                  org?.identities.find((i) => i.email === identity.email)
                    ?.primaryRole === IdentityRole.Client
                    ? org?.clientLogoUrl
                    : undefined
                }
                onSelectOrganization={() => {
                  switchOrganization({
                    id: org.id,
                  });
                }}
                rightSection={
                  <Text size="xs" c="dimmed">
                    Switch project
                  </Text>
                }
              />
            </Menu.Item>
          ))}
        </ScrollAreaAutosize>
      </Menu.Dropdown>
    </Menu>
  );
}
