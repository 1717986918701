import {
  Button,
  Menu,
  ThemeIcon,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconHelp,
  IconLogout,
  IconMoon,
  IconSettings,
  IconSun,
  IconUser,
} from "@tabler/icons-react";
import { EventName, IdentityRole } from "core";
import { Link } from "react-router-dom";
import { useOptionalAuthContext } from "../../auth/useAuthContext";
import { useIsRole } from "../../auth/useIdentity";
import { openChatSupport } from "../../utils/chatSupport";
import { useTrackEvent } from "../../utils/useTrackEvent";
import classes from "./Layout.module.css";

export function UserMenu({
  variant = "default",
}: {
  variant?: "sidebar" | "default";
}) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });

  const { signOut } = useOptionalAuthContext();
  const { track } = useTrackEvent();

  const isClient = useIsRole(IdentityRole.Client);

  function onSignOut() {
    track(EventName.LoggedOut);
    signOut();
  }

  return (
    <Menu shadow="sm" position="bottom-end">
      <Menu.Target>
        <Button
          color={variant === "sidebar" ? "dark" : undefined}
          size={variant === "sidebar" ? "sm" : undefined}
          variant="subtle"
          leftSection={
            <ThemeIcon
              variant="transparent"
              color={variant === "sidebar" ? "text" : undefined}
              size={"sm"}
            >
              <IconUser />
            </ThemeIcon>
          }
          classNames={{
            root: classes.accountButton,
            section: classes.accountButtonSection,
            label: classes.accountButtonLabel,
          }}
        >
          Account
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {!isClient && (
          <Menu.Item
            component={Link}
            leftSection={
              <ThemeIcon variant="transparent" color="dark" size="xs">
                <IconSettings />
              </ThemeIcon>
            }
            to="/account"
          >
            Settings
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() =>
            setColorScheme(computedColorScheme === "light" ? "dark" : "light")
          }
          leftSection={
            <ThemeIcon variant="transparent" color="dark" size="xs">
              {computedColorScheme === "light" ? <IconMoon /> : <IconSun />}
            </ThemeIcon>
          }
        >
          {computedColorScheme === "light" ? "Dark" : "Light"} mode
        </Menu.Item>
        <Menu.Item
          onClick={openChatSupport}
          leftSection={
            <ThemeIcon variant="transparent" color="dark" size="xs">
              <IconHelp />
            </ThemeIcon>
          }
        >
          Livechat support
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          color="red.4"
          leftSection={
            <ThemeIcon variant="transparent" size="xs" color="red.4">
              <IconLogout />
            </ThemeIcon>
          }
          onClick={onSignOut}
        >
          Sign Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
