import { z } from "zod";
import { CategoryBucket } from "./Category";
import {
  ConvoConfigurationSchema,
  ConvoEntryContentSchema,
  ConvoEntrySchema,
  ConvoSchema,
} from "./Convo";
import {
  PaginationMetaResponseSchema,
  PaginationQuerySchema,
  SortingQuerySchema,
} from "./Pagination";

export enum ExpandableAssetParam {
  ActiveEntry = "activeEntry",
}

export const ListAssetsQuerySchema = z
  .object({
    category: z.array(z.nativeEnum(CategoryBucket)).optional().default([]),
    expand: z.array(z.nativeEnum(ExpandableAssetParam)).optional().default([]),
    pagination: z.enum(["header", "body"]).optional(),
  })
  .merge(PaginationQuerySchema)
  .merge(SortingQuerySchema);

const ActiveEntrySchema = z.object({
  id: ConvoEntrySchema.shape.id,
  entryType: ConvoEntryContentSchema.shape.entryType,
  plainText: z.string(),
  htmlText: z.string(),
});

const AssetSchema = z.object({
  id: z.string(),
  originalId: ConvoSchema.shape.id,
  externalId: ConvoSchema.shape.externalId.nullable(),
  title: ConvoSchema.shape.title.nullable(),
  category: ConvoConfigurationSchema.shape.bucket.nullable(),
  createdAt: ConvoSchema.shape.createdAt,
  updatedAt: ConvoSchema.shape.updatedAt.nullable(),
  activeEntry: z
    .object({
      prompt: ActiveEntrySchema,
      result: ActiveEntrySchema,
    })
    .nullable(),
});

export const ListAssetsResponseSchema = z.union([
  z.array(AssetSchema),
  z
    .object({
      items: z.array(AssetSchema),
    })
    .merge(PaginationMetaResponseSchema),
]);

export type ListAssetsQuery = z.infer<typeof ListAssetsQuerySchema>;
export type ListAssetsResponse = z.infer<typeof ListAssetsResponseSchema>;
