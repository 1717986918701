import { z } from "zod";
import { CategoryBucket } from "./Category";
import { Connector } from "./Connection";
import { IdentityEmbedSchema } from "./Identity";

export enum NoteProvenanceSource {
  BuildBlock = "build-block",
  GlobalIdea = "global-idea",
}

const NoteProvenanceSchema = z.object({
  source: z.nativeEnum(NoteProvenanceSource),
  blockPath: z.string().optional(),
});

export const NoteContentSchema = z.object({
  provenance: NoteProvenanceSchema,
  title: z.string().optional(),
  bucket: z.nativeEnum(CategoryBucket).optional(),
  plainText: z.string(),
});

export const NoteSchema = z.object({
  id: z.string().uuid(),
  private: z.boolean().default(false),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  content: NoteContentSchema,
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  removedAt: z.date().nullable(),
  createdByAssistant: z.boolean(),
  createdByConnector: z.union([z.nativeEnum(Connector), z.string()]).nullable(),
  creator: IdentityEmbedSchema,
  externalId: z.string().nullable(),
});

export const FilterNoteParamsSchema = z.object({
  source: z.nativeEnum(NoteProvenanceSource).optional(),
  blockPath: z.string().optional(),
  inBucket: z.nativeEnum(CategoryBucket).optional(),
  skip: z.number().int().optional(),
  take: z.number().int().max(100).optional(),
});

export const CreateNoteSchema = z.object({
  externalId: NoteSchema.shape.externalId.optional(),
  title: NoteContentSchema.shape.title.optional(),
  content: NoteContentSchema.shape.plainText,
});

export const CreateNoteResponseSchema = z.object({
  note: z.object({
    id: NoteSchema.shape.id,
    title: NoteContentSchema.shape.title.nullable(),
    content: NoteContentSchema.shape.plainText,
    createdAt: NoteSchema.shape.createdAt,
  }),
});

export type Note = z.infer<typeof NoteSchema>;
export type NoteContent = z.infer<typeof NoteContentSchema>;
export type FilterNoteParams = z.infer<typeof FilterNoteParamsSchema>;
export type CreateNoteResponse = z.infer<typeof CreateNoteResponseSchema>;
