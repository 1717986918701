import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Group,
  Image,
  ScrollArea,
  Skeleton,
  Stack,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { ModalsProvider } from "@mantine/modals";
import {
  IconActivity,
  IconAssembly,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconNotebook,
  IconPencil,
  IconSchool,
  IconStack2,
} from "@tabler/icons-react";
import { EventName, FeatureFlag } from "core";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LayoutProps } from ".";
import { useAuthContext } from "../../auth/useAuthContext";
import { useClientMode } from "../../utils/useClientMode";
import { useIsFeatureEnabled } from "../../utils/useIsFeatureEnabled";
import { useOrganizations } from "../../utils/useOrganizations";
import { useTrackEvent } from "../../utils/useTrackEvent";
import { ZedIndex } from "../../utils/zedIndex";
import { FirstTimeVideoModal } from "../FirstTimeVideo";
import { NoanLogo } from "../icons/NoanLogo.svg";
import { NavLink } from "../NavLink";
import { ReaderPanelContextProvider } from "../ReaderPanel/ReaderPanelContext";
import classes from "./Layout.module.css";
import { ProjectSwitcher } from "./ProjectSwitcher";
import { UserMenu } from "./UserMenu";

export function SidebarLayout({ topHeader }: Pick<LayoutProps, "topHeader">) {
  const location = useLocation();

  const [
    onboardingVideoOpen,
    { open: openOnboardingVideo, close: closeOnboardingVideo },
  ] = useDisclosure();

  /**
   * Collapse/hide navigation + mobile behavior
   */
  const [navOpened, { toggle: toggleNav, close: closeNav }] = useDisclosure();
  const [navCollapsed, setNavCollapsed] = useLocalStorage({
    key: "noan-navbar-collapsed",
    defaultValue: false,
  });

  const { isClient, isCreateModeEnabled } = useClientMode();
  const { track } = useTrackEvent();
  /**
   * Account information + org switcher
   */
  const { identity } = useAuthContext();
  const { data: organizations, isLoading: isLoadingOrgs } = useOrganizations();

  const activeOrganization = organizations.find(
    (o) => o.id === identity.organizationId,
  );

  const orgLogo = activeOrganization?.clientLogoUrl;
  const isWorkspaceEnabled = useIsFeatureEnabled(FeatureFlag.UseWorkspace);

  const [viewedOnboardingVideo, setViewedOnboardingVideo] =
    useLocalStorage<boolean>({
      key: "noan-viewed-onboarding-video",
      getInitialValueInEffect: true,
      defaultValue: false,
    });

  const pulseOnboardingVideo = useMemo(
    () =>
      !viewedOnboardingVideo &&
      dayjs(identity.createdAt).isAfter(dayjs().subtract(3, "weeks")),
    [identity, viewedOnboardingVideo],
  );

  /**
   * Ensure the mobile nav gets toggled off when navigating elsewhere. This is important
   * if we're on mobile, otherwise the nav is over the content and you can't tell
   * we've navigated elsewhere
   */
  useEffect(() => {
    closeNav();
  }, [location, closeNav]);

  function onClickWatchOnboarding() {
    setViewedOnboardingVideo(true);
    openOnboardingVideo();
    track(EventName.ViewedOnboardingVideo);
  }

  function onCloseOnboardingVideo() {
    closeOnboardingVideo();
    track(EventName.ClosedOnboardingVideo);
  }

  return (
    <ModalsProvider
      modalProps={{
        withinPortal: true,
      }}
    >
      <ReaderPanelContextProvider>
        <AppShell
          layout="default"
          header={{ height: { base: 60, sm: 0 } }}
          navbar={{
            width: { base: navCollapsed ? 100 : 256 },
            breakpoint: "sm",
            collapsed: { mobile: !navOpened },
          }}
          padding={{
            base: 0,
          }}
          classNames={{
            root: classes.appShell,
            main: classes.appMain,
          }}
        >
          <AppShell.Navbar
            className={classes.sidebarNavbar}
            zIndex={navOpened ? ZedIndex.Sidebar : undefined}
          >
            {/* Desktop Logo */}
            <AppShell.Section>
              <Group className={classes.sidebarExpandButtonContainer}>
                <ActionIcon
                  variant="default"
                  color="dark"
                  onClick={() => setNavCollapsed(!navCollapsed)}
                  size="lg"
                  className={classes.sidebarExpandButton}
                >
                  {navCollapsed ? (
                    <IconLayoutSidebarLeftExpand />
                  ) : (
                    <IconLayoutSidebarLeftCollapse />
                  )}
                </ActionIcon>
              </Group>

              <Stack
                align="center"
                gap="md"
                justify="space-between"
                py="lg"
                px="sm"
                className={classes.sidebarLayoutLogo}
                visibleFrom="sm"
              >
                {isLoadingOrgs ? (
                  <Skeleton w={80} h={80} />
                ) : isClient && orgLogo ? (
                  <Image
                    src={orgLogo}
                    mah={80}
                    w="fit-content"
                    maw="100%"
                    fit="contain"
                    radius="sm"
                  />
                ) : (
                  <NoanLogo />
                )}
              </Stack>
            </AppShell.Section>

            <AppShell.Section grow component={ScrollArea}>
              <ProjectSwitcher />

              <Stack mt="lg" flex={1} gap={5} w="100%">
                {isWorkspaceEnabled && !isClient && (
                  <NavLink
                    to="/workspace"
                    icon={<IconAssembly />}
                    label="Assistant"
                  />
                )}

                <NavLink
                  to="/home"
                  icon={<IconActivity />}
                  label={isClient ? "Dashboard" : "Activity"}
                />
                {!isClient && (
                  <NavLink
                    to="/build"
                    icon={<IconStack2 />}
                    label="Knowledge"
                  />
                )}
                {isCreateModeEnabled && (
                  <NavLink to="/create" icon={<IconPencil />} label="Create" />
                )}
                {!isClient && (
                  <NavLink to="/ideas" icon={<IconNotebook />} label="Notes" />
                )}
              </Stack>
            </AppShell.Section>

            <Group
              align="center"
              justify="space-between"
              className={classes.accountButtonContainer}
            >
              {!isClient && (
                <Tooltip label="Watch a short onboarding video">
                  <ActionIcon
                    onClick={onClickWatchOnboarding}
                    variant={pulseOnboardingVideo ? "outline" : "subtle"}
                    size="lg"
                    color={pulseOnboardingVideo ? undefined : "dark"}
                    className={
                      (pulseOnboardingVideo && "animate-firstuse") || undefined
                    }
                  >
                    <IconSchool />
                  </ActionIcon>
                </Tooltip>
              )}
              <Group flex={1} justify="flex-end">
                <UserMenu variant="sidebar" />
              </Group>
            </Group>
            <FirstTimeVideoModal
              opened={onboardingVideoOpen}
              onClose={onCloseOnboardingVideo}
            />
          </AppShell.Navbar>
          <AppShell.Header hiddenFrom="sm">
            {/* Mobile Header + Logo */}
            <Group w="100%" h="100%" px="xl" justify="space-between">
              {isLoadingOrgs ? (
                <Skeleton w={35} h={35} />
              ) : isClient && orgLogo ? (
                <Image src={orgLogo} mah={35} fit="contain" radius="sm" />
              ) : (
                <NoanLogo width={90} />
              )}
              <Burger opened={navOpened} onClick={toggleNav} />
            </Group>
          </AppShell.Header>
          <AppShell.Main>
            {topHeader}
            <Box className={classes.outletContainer}>
              <Outlet />
            </Box>
          </AppShell.Main>
        </AppShell>
      </ReaderPanelContextProvider>
    </ModalsProvider>
  );
}
