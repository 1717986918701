import { PriceLookupKey } from "./models";

export const products = [
  {
    name: "Basic",
    items: [
      {
        id: PriceLookupKey.BasicMonthly,
        priceId: "price_1NuBd1B8g7YKzplObqEsyU29",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/9AQ17v2xv7056S4bIX",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplOUb94ATkl",
        defaultSeats: 1,
        defaultOrganizations: 5,
        extraOrganizationsPriceId: "price_1R4RBBB8g7YKzplOxYFVs2c3",
      },
      /**
       * @deprecated - Keep for compatibility with existing users.
       */
      {
        id: PriceLookupKey.BasicYearly,
        priceId: "price_1NuBd1B8g7YKzplOyMlzhiot",
        frequency: "year",
        paymentLink: "https://buy.stripe.com/4gw2bzgolbgla4gaES",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplO8eCyBb4S",
        defaultSeats: 1,
        extraOrganizationsPriceId: "price_1PG6SBB8g7YKzplO2RDQIeQs",
      },
    ],
  },
  {
    /**
     * @deprecated - Keep for compatibility with existing users.
     */
    name: "Scale",
    items: [
      {
        id: PriceLookupKey.ScaleMonthly,
        priceId: "price_1OuZJzB8g7YKzplOcf6qwQ3L",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/fZeg2p9ZXdot90cbIY",
        extraSeatsPriceId: "price_1P6dlhB8g7YKzplOZD62JlBY",
        defaultSeats: 3,
        extraOrganizationsPriceId: "price_1PG6QTB8g7YKzplOYnBcsele",
      },
      {
        id: PriceLookupKey.ScaleYearly,
        priceId: "price_1OuZJzB8g7YKzplO1fTdcvUM",
        frequency: "year",
        paymentLink: "https://buy.stripe.com/3csaI5b41esxekw14l",
        extraSeatsPriceId: "price_1P6dlhB8g7YKzplOUCJV6ysb",
        defaultSeats: 3,
        extraOrganizationsPriceId: "price_1PG6QTB8g7YKzplOPu4bjP2R",
      },
    ],
  },
];

// Price IDs of all NOAN products
export const productItemsPriceIds = products.flatMap((p) =>
  p.items.map((i) => i.priceId),
);

export const extraSeatsPriceIds = products
  .flatMap((p) => p.items)
  .map((i) => i.extraSeatsPriceId);

export const extraOrganizationsPriceIds = products
  .flatMap((p) => p.items)
  .map((i) => i.extraOrganizationsPriceId);
