import {
  Box,
  Center,
  Group,
  Modal,
  Skeleton,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconConfetti } from "@tabler/icons-react";
import { useWelcomeVideo } from "./useWelcomeVideo";
import { WelcomeVideoPlayer } from "./WelcomeVideoPlayer";

function FirstTimePopupContent() {
  const { content, videoSrc, isPortrait } = useWelcomeVideo();

  if (!content) {
    return (
      <Center p="xl">
        <Skeleton w="100%" h="100%" mih={380} />
      </Center>
    );
  }

  return (
    <Box>
      {content.tagline && (
        <Text size="sm" mb="sm">
          {content.tagline}
        </Text>
      )}
      <WelcomeVideoPlayer isPortrait={isPortrait} videoSrc={videoSrc} />
    </Box>
  );
}

export function FirstTimeVideoModal({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) {
  if (!opened) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size="xl"
      centered
      title={
        <Group gap="xs">
          <ThemeIcon size="lg" variant="transparent" color="dark">
            <IconConfetti />
          </ThemeIcon>
          <Text size="lg" fw={600} span>
            Welcome to NOAN!
          </Text>
        </Group>
      }
    >
      <Center>{opened && <FirstTimePopupContent />}</Center>
    </Modal>
  );
}
