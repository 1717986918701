import { z } from "zod";

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export const SortingQuerySchema = z.object({
  sort: z.string().optional(),
  order: z.nativeEnum(SortOrder).optional(),
});

export const PaginationQuerySchema = z.object({
  page: z
    .string()
    .optional()
    .transform((val) => (val ? parseInt(val, 10) : 1)) // Default to 1 if not provided
    .refine((val) => val > 0, { message: "Page must be a positive integer" }),
  per_page: z
    .string()
    .optional()
    .transform((val) => (val ? parseInt(val, 10) : 10)) // Default to 10 if not provided
    .refine((val) => val > 0 && val <= 100, {
      message: "per_page must be between 1 and 100",
    }),
});

export const PaginationOutputSchema = z.object({
  pagination: z.object({
    total: z.number(),
  }),
});

export const PaginationMetaResponseSchema = z.object({
  meta: z.object({
    page: z.number(),
    perPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number(),
    sort: z.string().optional(),
    order: z.nativeEnum(SortOrder).optional(),
    hasPrev: z.boolean(),
    hasNext: z.boolean(),
  }),
  links: z.object({
    self: z.string(),
    prev: z.string().nullable(),
    next: z.string().nullable(),
    first: z.string().nullable(),
    last: z.string(),
  }),
});

export type SortingQuery = z.infer<typeof SortingQuerySchema>;
export type PaginationQuery = z.infer<typeof PaginationQuerySchema>;
export type PaginationMetaResponse = z.infer<
  typeof PaginationMetaResponseSchema
>;
