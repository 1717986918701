import { AspectRatio } from "@mantine/core";
import ReactPlayer from "react-player";
import classes from "./FirstTimeVideo.module.css";

export function WelcomeVideoPlayer({
  videoSrc,
  isPortrait,
}: {
  videoSrc?: string;
  isPortrait?: boolean;
}) {
  return (
    <ReactPlayer
      playing
      loop
      playsinline
      controls
      wrapper={({ children }) => {
        return (
          <AspectRatio
            h={isPortrait ? 480 : "100%"}
            ratio={isPortrait ? 2 / 3 : 16 / 9}
            className={classes.welcomeVideoWrapper}
          >
            {children}
          </AspectRatio>
        );
      }}
      url={videoSrc}
      stopOnUnmount
    />
  );
}
