/**
 * Removes nullish values from an array
 */
export function compact<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null) as NonNullable<T>[];
}

/**
 * Similar to `compact`, but also checks for empty strings
 */
export function compactString<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((v) => v != null && v !== "") as NonNullable<T>[];
}

/**
 * Raises a typescript error + throws if a branch that should be unreachable
 * is, infact, reachable. Can be used for example in switch statements to ensure
 * all options are covered.
 */
export function assertUnreachable(
  _: never,
  message: string = "invariant: Reached unreachable branch or condition",
): never {
  throw new Error(message);
}

/**
 * Tries to split a string containing a full name into parts. Very
 * iffy and sketchy.
 */
export function splitFullName(fullName: string) {
  const name = fullName.split(" ");

  return {
    lastName: name[name.length - 1],
    firstName: name.slice(0, -1).join(" "),
  };
}

/**
 * Deletes the object keys that have null or undefined values.
 * @param obj
 * @returns The input object with valid values
 */
export function cleanObject(obj: Record<string, unknown>) {
  return Object.entries(obj).reduce(
    (a, [key, value]) => (value == null ? a : ((a[key] = value), a)),
    {} as Record<string, unknown>,
  );
}

/**
 * Truncates a string to a specified length and appends an ellipsis if truncated.
 *
 * If the input text is undefined, an empty string is returned.
 * This is useful for API responses or UI elements where a text preview is enough.
 *
 * @param text - The input text to truncate (optional).
 * @param maxLength - The maximum length before truncation.
 * @returns - The truncated text with "..." appended if it exceeds maxLength,
 *            or an empty string if no text is provided.
 */
export function shortenText(text?: string, maxLength = 256) {
  return text
    ? `${text.slice(0, maxLength)}${text.length > maxLength ? "..." : ""}`
    : "";
}
