import { useMediaQuery } from "@mantine/hooks";
import { useStoryblok } from "@storyblok/react";
import type { WelcomeVideoStoryblok } from "../../storyblok-types";

export function useWelcomeVideo() {
  const story = useStoryblok("welcome-video-popup", {
    version: "published",
  });

  const isPortrait = useMediaQuery("screen and (orientation: portrait)");
  const content = story.content as WelcomeVideoStoryblok | null;

  const desktopVideo = content?.desktopVideo.filename;
  const mobileVideo = content?.mobileVideo?.filename || desktopVideo;
  const videoSrc = isPortrait ? mobileVideo : desktopVideo;

  return {
    videoSrc,
    isPortrait,
    content,
  };
}
