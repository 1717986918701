import { z } from "zod";

export enum Connector {
  Stripe = "stripe",
  GoogleAnalytics = "google-analytics",
  Zapier = "zapier",
}

export type NangoConnector = Connector.GoogleAnalytics | Connector.Stripe;

export type ApiKeyConnector = Connector.Zapier;
export const apiKeyConnectors = [Connector.Zapier];

export enum GABlockPathKeys {
  GA = "ga",
}

export enum NangoModelName {
  GAReportPerformanceOverview = "GAReportPerformanceOverview",
  GAReportTrafficOverview = "GAReportTrafficOverview",
  GAReportTopContent = "GAReportTopContent",
  GAReportTrafficSource = "GAReportTrafficSource",
  GAReportEngagement = "GAReportEngagement",
}

export const NangoIntegrationIds = {
  [Connector.Stripe]: "com.noan.stripe-connector",
  [Connector.GoogleAnalytics]: "com.noan.ga-connector",
} as const;

export const nangoIntegrations = Object.values(NangoIntegrationIds);

const NangoConnectionSchema = z.object({
  nangoConnectionId: z.string(),
  nangoIntegrationId: z.string(),
});

const StripeConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.Stripe),
  })
  .merge(NangoConnectionSchema);

const GoogleAnalyticsConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.GoogleAnalytics),
    /**
     * The Google Analytics account the organization is connected to. Can be a website or an app.
     */
    connectedAccount: z
      .object({
        id: z.string(),
        name: z.string(),
        propertyName: z.string(),
        propertyDisplayName: z.string(),
      })
      .optional(),
  })
  .merge(NangoConnectionSchema);

const ZapierConnectorConfigurationSchema = z.object({
  connector: z.literal(Connector.Zapier),
  apiKey: z.string(),
  maskedApiKey: z.string(),
});

export const ConnectorConfigurationSchema = z.discriminatedUnion("connector", [
  StripeConnectorConfigurationSchema,
  GoogleAnalyticsConnectorConfigurationSchema,
  ZapierConnectorConfigurationSchema,
]);

export const NangoConnectorConfigurationSchema = z.discriminatedUnion(
  "connector",
  [
    StripeConnectorConfigurationSchema,
    GoogleAnalyticsConnectorConfigurationSchema,
  ],
);

export type GoogleAnalyticsConnectorConfiguration = z.infer<
  typeof GoogleAnalyticsConnectorConfigurationSchema
>;
export type StripeConnectorConfiguration = z.infer<
  typeof StripeConnectorConfigurationSchema
>;
export type ZapierConnectorConfiguration = z.infer<
  typeof ZapierConnectorConfigurationSchema
>;

export type ConnectorConfiguration = z.infer<
  typeof ConnectorConfigurationSchema
>;

export type NangoConnectorConfiguration = z.infer<
  typeof NangoConnectorConfigurationSchema
>;

export function isConnectorGoogleAnalytics(
  configuration?: ConnectorConfiguration,
) {
  return configuration?.connector === Connector.GoogleAnalytics;
}
export function isConnectorStripe(configuration?: ConnectorConfiguration) {
  return configuration?.connector === Connector.Stripe;
}
export function isConnectorZapier(configuration?: ConnectorConfiguration) {
  return configuration?.connector === Connector.Zapier;
}
